/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, debounce, delay, put, select, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from 'utils';

import { Dimensions } from 'components/Incrementality/incrementalityTypes';
import { DateRangeValue } from 'components/common';

import { dataApiSlice, selectMetrics, selectTimeSlice } from 'modules/apiData/dataApiSlice';
import { authStorage } from 'modules/auth/authStorage';
import { Metrics, TimeSlice } from 'modules/misc/miscApiTypes';
import { PagingRequestValues } from 'modules/organization/organizationApiTypes';
import { getCurrentAdvertiserId } from 'modules/organization/organizationSlice';

import {
  CAMPAIGN_DIMENSION,
  FREQUENCY_METRIC,
  REACH_METRIC,
  SPEND_BUDGET_METRIC,
} from './constants';
import { performanceApi } from './performanceApi';
import {
  AdvancedConfiguration,
  AnalyticsRequest,
  AnalyticsResponse,
  BudgetRequest,
  BudgetResponse,
  ChartRequest,
  CreateViewRequest,
  CreativeNamesPagingResponse,
  CreativesPagingResponse,
  DeleteViewRequest,
  DimensionFilter,
  Halo,
  HaloRequest,
  Incrementality,
  IncrementalityFilters,
  IncrementalityRequest,
  NestedAnalyticsRequest,
  PerformanceResponse,
  Publisher,
  PublishersPagingResponse,
  TabKeys,
  View,
  WidgetRequest,
  WidgetResponse,
} from './performanceApiTypes';
import {
  currentViewId,
  deleteViewFailure,
  deleteViewStart,
  deleteViewSuccess,
  endDate,
  filterWidgets,
  filterWidgetsStart,
  getAnalyticsDataFailure,
  getAnalyticsDataStart,
  getAnalyticsDataSuccess,
  getBudgetDataFailure,
  getBudgetDataStart,
  getBudgetDataSuccess,
  getChartDataFailure,
  getChartDataStart,
  getChartDataSuccess,
  getCreativeNamesFailure,
  getCreativeNamesStart,
  getCreativeNamesSuccess,
  getCreativesFailure,
  getCreativesStart,
  getCreativesSuccess,
  getCustomView,
  getIncrementalityDataFailure,
  getIncrementalityDataStart,
  getIncrementalityDataSuccess,
  getPublishersFailure,
  getPublishersStart,
  getPublishersSuccess,
  getSelectedMetrics,
  getViewsFailure,
  getViewsStart,
  getViewsSuccess,
  getWidgetDataFailure,
  getWidgetDataStart,
  getWidgetDataSuccess,
  range,
  saveViewFailure,
  saveViewStart,
  saveViewSuccess,
  setCurrentViewId,
  setGlobalFilterDimensions,
  setSelectedMetrics,
  startDate,
  views,
  cadence,
  cumulative,
  getGlobalFilterDimensions,
  setDate,
  setCumulative,
  setCadence,
  setSelectedDimensions,
  getSelectedDimensions,
  setSelectedColumns,
  advancedConfiguration,
  incrementalityFilters,
  setAdvancedConfiguration,
  invalidatePerformanceCache,
  getAnalyticsPage,
  getAnalyticsPageSize,
  getAnalyticsOrder,
  getAnalyticsOrderBy,
  setAnalyticsPage,
  setAnalyticsPageSize,
  setAnalyticsOrder,
  setAnalyticsOrderBy,
  selectedColumns,
  setIncrementalityFilter,
  invalidateApiData,
  getHaloDataStart,
  getHaloDataSuccess,
  getHaloDataFailure,
  getIncrementalityOrder,
  getHaloOrder,
  setActiveTab,
  activeTab,
  setHaloFilter,
  haloFilter,
  setIncrementalityOrder,
  setHaloOrder,
} from './performanceSlice';

function* getViewsFlow(action: PayloadAction<string>) {
  try {
    const views: View[] = yield call(performanceApi.getViews, action.payload);
    const selectedViewId: string = yield select(currentViewId);

    if (
      (!selectedViewId || !views.some((v) => v.viewId === selectedViewId)) &&
      views.length > 0 &&
      views[0].viewId
    ) {
      yield put(setCurrentViewId(views[0].viewId));
    }

    yield put(getViewsSuccess(views));
  } catch (error) {
    yield put(getViewsFailure(getErrorMessage(error)));
  }
}

function* saveViewFlow(action: PayloadAction<CreateViewRequest>) {
  try {
    const view: View = yield select(getCustomView);
    const allViews: View[] = yield select(views);
    const viewId: string = yield select(currentViewId);

    const metrics: string[] = allViews.find((v) => v.viewId === viewId)?.metrics || [];
    const graphs: string[] = yield select(getSelectedMetrics);

    // add a new custom view or edit an existing custom view
    const updatedView: View =
      action.payload.viewId === '0'
        ? {
            ...view,
            name: action.payload.name,
            isDefault: action.payload.isDefault,
          }
        : {
            ...action.payload,
            metrics: metrics,
            graphs: graphs,
          };

    delete updatedView.isCustom;
    const savedView: View = yield call(performanceApi.saveView, updatedView);
    const response: View[] = yield call(performanceApi.getViews, action.payload.advertiserId);

    yield put(saveViewSuccess(response));
    yield put(setCurrentViewId(savedView.viewId!));
  } catch (error) {
    yield put(saveViewFailure(getErrorMessage(error)));
  }
}

function* deleteViewFlow(action: PayloadAction<DeleteViewRequest>) {
  try {
    const selectedViewId: string = yield select(currentViewId);
    const isSelectedViewDeleted: boolean = selectedViewId === action.payload.viewId ? true : false;

    yield call(performanceApi.deleteView, action.payload);
    const response: View[] = yield call(performanceApi.getViews, action.payload.advertiserId);

    yield put(deleteViewSuccess({ views: response, isSelectedViewDeleted: isSelectedViewDeleted }));
    yield put(getWidgetDataStart());
  } catch (error) {
    yield put(deleteViewFailure(getErrorMessage(error)));
  }
}

function* getWidgetsFlow() {
  try {
    const selectedAdvertiserId: string = yield select(getCurrentAdvertiserId);
    const selectedStartDate: string = yield select(startDate);
    const selectedEndDate: string = yield select(endDate);
    const selectedViewId: string = yield select(currentViewId);

    if (!selectedAdvertiserId || !selectedStartDate || !selectedEndDate || !selectedViewId) return;

    const selectedRange: string = yield select(range);
    const advancedConfigurations: AdvancedConfiguration = yield select(advancedConfiguration);
    const currentViews: View[] = yield select(views);
    const currentView = currentViews?.find((v) => v.viewId === selectedViewId);
    const globalFilterDimensions: DimensionFilter[] = yield select(getGlobalFilterDimensions);
    const metrics: Metrics = yield select(selectMetrics);

    if (currentView && currentView.metrics && currentView.metrics.length > 0) {
      const widgetRequest: WidgetRequest = {
        metrics: Object.keys(metrics),
        advertiserId: selectedAdvertiserId,
        startDateTime: selectedStartDate,
        endDateTime: selectedEndDate,
        showUniques: advancedConfigurations?.showUniques,
        methodology: advancedConfigurations?.methodology,
        conversionWindow: advancedConfigurations?.conversionWindow,
        timeSlice: selectedRange,
        dimensionFilters: globalFilterDimensions,
      };

      const response: WidgetResponse = yield call(performanceApi.getWidgetData, widgetRequest);

      yield put(filterWidgetsStart(response));
      yield put(getWidgetDataSuccess(response));
    }
  } catch (error) {
    yield put(getWidgetDataFailure(getErrorMessage(error)));
  }
}

function* getBudgetFlow() {
  try {
    const selectedAdvertiserId: string = yield select(getCurrentAdvertiserId);
    const globalFilterDimensions: DimensionFilter[] = yield select(getGlobalFilterDimensions);

    const filteredDimensions = globalFilterDimensions.filter(
      (item) => item.dimension === CAMPAIGN_DIMENSION,
    );

    if (!filteredDimensions || filteredDimensions.length === 0) return;

    const budgetRequest: BudgetRequest = {
      advertiserId: selectedAdvertiserId,
      dimensionFilters: filteredDimensions,
    };

    const response: BudgetResponse = yield call(performanceApi.getBudgetData, budgetRequest);

    yield put(getBudgetDataSuccess(response));
  } catch (error) {
    yield put(getBudgetDataFailure(getErrorMessage(error)));
  }
}

function* setGlobalFilterDimensionsFlow(action: PayloadAction<DimensionFilter[]>) {
  const selectedTab: string = yield select(activeTab);

  if (action.payload.some((item) => item.dimension === CAMPAIGN_DIMENSION)) {
    yield put(getBudgetDataStart());
  }
  yield put(getChartDataStart());
  yield put(setActiveTab(selectedTab));
  yield put(getWidgetDataStart());
}

function* setDateFlow(action: PayloadAction<DateRangeValue>) {
  const selectedTab: string = yield select(activeTab);

  yield put(getChartDataStart());
  yield put(getWidgetDataStart());
  yield put(setActiveTab(selectedTab));
}

function* setCadenceFlow(action: PayloadAction<string>) {
  yield put(getChartDataStart());
}

function* setCumulativeFlow(action: PayloadAction<string>) {
  yield put(getChartDataStart());
}

function* setSelectedDimensionsFlow(action: PayloadAction<[string | null, string | null]>) {
  const selectedTab: string = yield select(activeTab);

  yield put(setActiveTab(selectedTab));
}

function* setSelectedColumnsFlow(action: PayloadAction<string[]>) {
  yield put(getAnalyticsDataStart());
}

function* setAdvancedConfigurationFlow(action: PayloadAction<AdvancedConfiguration>) {
  const selectedTab: string = yield select(activeTab);

  yield put(getWidgetDataStart());
  yield put(getChartDataStart());
  yield put(setActiveTab(selectedTab));
}

function* setIncrementalityFilterFlow(action: PayloadAction<IncrementalityFilters>) {
  const selectedTab: string = yield select(activeTab);

  yield put(setActiveTab(selectedTab));
}

function* setHaloFilterFlow(action: PayloadAction<string>) {
  const selectedTab: string = yield select(activeTab);

  yield put(setActiveTab(selectedTab));
}

function* activeTabFlow(action: PayloadAction<string>) {
  switch (action.payload) {
    case TabKeys.INCREMENTALITY.value:
      yield put(getIncrementalityDataStart());

      return;
    case TabKeys.RIPPLE_EFFECT.value:
      yield put(getHaloDataStart());

      return;
    case TabKeys.REAL_IMPACT.value:
      yield put(getAnalyticsDataStart());

      return;
    default:
      return;
  }
}

function* invalidatePerformanceCacheFlow() {
  const selectedTab: string = yield select(activeTab);

  yield put(getWidgetDataStart());
  yield put(getChartDataStart());
  yield put(setActiveTab(selectedTab));
}

function* invalidateApiDataFlow() {
  yield put(dataApiSlice.util.invalidateTags(['ApiData']));
}

function* changeViewFlow(action: PayloadAction<string>) {
  yield put(getWidgetDataStart());
}

function* filterWidgetsFlow(action: PayloadAction<WidgetResponse>) {
  const selectedViewId: string = yield select(currentViewId);
  const currentViews: View[] = yield select(views);
  const currentView = currentViews?.find((v) => v.viewId === selectedViewId);
  const metrics: Metrics = yield select(selectMetrics);
  const selectedRange: string = yield select(range);
  const timeSlices: TimeSlice = yield select(selectTimeSlice);
  const selectedTab: string = yield select(activeTab);

  if (currentView && currentView.metrics && currentView.metrics.length > 0) {
    if (currentView.metrics.includes(SPEND_BUDGET_METRIC)) {
      yield put(getBudgetDataStart());
    }

    yield put(
      filterWidgets({
        widgets: action.payload,
        displayNames: metrics,
        viewMetrics: currentView.metrics,
        graphs: currentView.graphs,
        timeSlice: timeSlices[selectedRange],
        timeSliceOption: selectedRange,
      }),
    );
    yield put(getChartDataStart());
    yield put(setActiveTab(selectedTab));
  }
}

function* changeSelectedMetricsFlow(action: PayloadAction<string[]>) {
  const selectedTab: string = yield select(activeTab);

  yield put(getChartDataStart());
  yield put(setActiveTab(selectedTab));
}

function* getChartFlow() {
  try {
    const selectedAdvertiserId: string = yield select(getCurrentAdvertiserId);
    const selectedStartDate: string = yield select(startDate);
    const selectedEndDate: string = yield select(endDate);
    const selectedCadence: string = yield select(cadence);
    const isCumulative: boolean = yield select(cumulative);
    const dimensionFilters: DimensionFilter[] = yield select(getGlobalFilterDimensions);
    const advancedConfigurations: AdvancedConfiguration = yield select(advancedConfiguration);
    let selectedMetrics: string[] = yield select(getSelectedMetrics);

    if (!selectedMetrics || selectedMetrics.length === 0) {
      yield put(getChartDataSuccess(null));

      return;
    }

    if (isCumulative) {
      selectedMetrics = selectedMetrics.filter(
        (metric) => metric !== FREQUENCY_METRIC && metric !== REACH_METRIC,
      );
    }

    const chartRequest: ChartRequest = {
      metrics: selectedMetrics,
      advertiserId: selectedAdvertiserId,
      startDateTime: selectedStartDate,
      endDateTime: selectedEndDate,
      cadence: selectedCadence,
      cumulative: isCumulative,
      showUniques: advancedConfigurations?.showUniques,
      methodology: advancedConfigurations?.methodology,
      conversionWindow: advancedConfigurations?.conversionWindow,
      dimensionFilters: dimensionFilters,
    };

    const response: PerformanceResponse = yield call(performanceApi.getChartData, chartRequest);

    yield put(getChartDataSuccess(response));
  } catch (error) {
    yield put(getChartDataFailure(getErrorMessage(error)));
  }
}

function* getIncrementalityFlow() {
  try {
    const selectedAdvertiserId: string = yield select(getCurrentAdvertiserId);
    const selectedStartDate: string = yield select(startDate);
    const selectedEndDate: string = yield select(endDate);
    const globalFilterDimensions: DimensionFilter[] = yield select(getGlobalFilterDimensions);
    const advancedConfigurations: AdvancedConfiguration = yield select(advancedConfiguration);
    const incrementalityFilter: IncrementalityFilters = yield select(incrementalityFilters);
    const order: string = yield select(getIncrementalityOrder);

    const filteredDimensions = globalFilterDimensions.filter(
      (item) => item.dimension === CAMPAIGN_DIMENSION,
    );

    if (
      !incrementalityFilter.metric ||
      !filteredDimensions ||
      filteredDimensions.length === 0 ||
      !selectedAdvertiserId ||
      !selectedStartDate ||
      !selectedEndDate
    ) {
      yield put(
        getIncrementalityDataSuccess({ overallIncrementality: null, incrementalities: null }),
      );

      return;
    }

    const incrementalityRequest: IncrementalityRequest = {
      metric: incrementalityFilter.metric,
      dimension:
        incrementalityFilter.dimension !== Dimensions.OVERALL.value
          ? incrementalityFilter.dimension
          : null,
      dimensionFilters: globalFilterDimensions,
      advertiserId: selectedAdvertiserId,
      startDateTime: selectedStartDate,
      endDateTime: selectedEndDate,
      showUniques: advancedConfigurations?.showUniques,
      methodology: advancedConfigurations?.methodology,
      conversionWindow: advancedConfigurations?.conversionWindow,
      order: order,
    };

    const incrementalities: Incrementality[] = yield call(
      performanceApi.getIncrementalityData,
      incrementalityRequest,
    );

    let overallIncrementality = incrementalities ? incrementalities[0] : null;

    if (incrementalityFilter.dimension !== Dimensions.OVERALL.value) {
      const overallResponse: Incrementality[] = yield call(performanceApi.getIncrementalityData, {
        ...incrementalityRequest,
        dimension: null,
      });

      overallIncrementality = overallResponse ? overallResponse[0] : null;
    }

    yield put(
      getIncrementalityDataSuccess({
        overallIncrementality: overallIncrementality,
        incrementalities: incrementalities,
      }),
    );
  } catch (error) {
    yield put(getIncrementalityDataFailure(getErrorMessage(error)));
  }
}

function* getHaloFlow() {
  try {
    const selectedAdvertiserId: string = yield select(getCurrentAdvertiserId);
    const selectedStartDate: string = yield select(startDate);
    const selectedEndDate: string = yield select(endDate);
    const globalFilterDimensions: DimensionFilter[] = yield select(getGlobalFilterDimensions);
    const advancedConfigurations: AdvancedConfiguration = yield select(advancedConfiguration);
    const metric: string = yield select(haloFilter);
    const order: string = yield select(getHaloOrder);

    const filteredDimensions = globalFilterDimensions.filter(
      (item) => item.dimension === CAMPAIGN_DIMENSION,
    );

    if (
      !filteredDimensions ||
      filteredDimensions.length === 0 ||
      !selectedAdvertiserId ||
      !selectedStartDate ||
      !selectedEndDate
    ) {
      yield put(getHaloDataSuccess(null));

      return;
    }

    const haloRequest: HaloRequest = {
      metric: metric,
      dimensionFilters: globalFilterDimensions,
      advertiserId: selectedAdvertiserId,
      startDateTime: selectedStartDate,
      endDateTime: selectedEndDate,
      showUniques: advancedConfigurations?.showUniques,
      methodology: advancedConfigurations?.methodology,
      conversionWindow: advancedConfigurations?.conversionWindow,
      order: order,
    };

    const response: Halo = yield call(performanceApi.getHaloData, haloRequest);

    yield put(getHaloDataSuccess(response));
  } catch (error) {
    yield put(getHaloDataFailure(getErrorMessage(error)));
  }
}

function* getAnalyticsFlow() {
  try {
    const selectedAdvertiserId: string = yield select(getCurrentAdvertiserId);
    const selectedStartDate: string = yield select(startDate);
    const selectedEndDate: string = yield select(endDate);
    const currentView: string = yield select(currentViewId);
    const allViews: View[] = yield select(views);
    const viewMetrics = allViews?.find((view) => view.viewId === currentView)?.metrics || [];
    const columns: string[] = yield select(selectedColumns);
    const globalFilterDimensions: DimensionFilter[] = yield select(getGlobalFilterDimensions);
    const selectedDimensions: [string | null, string | null] = yield select(getSelectedDimensions);
    const advancedConfigurations: AdvancedConfiguration = yield select(advancedConfiguration);
    const page: number = yield select(getAnalyticsPage);
    const size: number = yield select(getAnalyticsPageSize);
    const order: string = yield select(getAnalyticsOrder);
    const orderBy: string = yield select(getAnalyticsOrderBy);

    if (!viewMetrics.length || !selectedAdvertiserId || !selectedStartDate || !selectedEndDate) {
      yield put(getAnalyticsDataSuccess(null));

      return;
    }

    let response: AnalyticsResponse = {
      data: [],
      totalPages: 0,
      totalCount: 0,
    };

    if (!selectedDimensions[1]) {
      const chartRequest: AnalyticsRequest = {
        metrics: columns?.length ? columns : viewMetrics,
        dimension: selectedDimensions[0] || '',
        advertiserId: selectedAdvertiserId,
        startDateTime: selectedStartDate,
        endDateTime: selectedEndDate,
        showUniques: advancedConfigurations?.showUniques,
        methodology: advancedConfigurations?.methodology,
        conversionWindow: advancedConfigurations?.conversionWindow,
        page,
        size,
        order,
        orderBy,
        dimensionFilters: globalFilterDimensions,
      };

      response = yield call(performanceApi.getAnalyticsData, chartRequest);
    } else {
      const analyticsRequest: NestedAnalyticsRequest = {
        metrics: columns?.length ? columns : viewMetrics,
        parentDimension: selectedDimensions[0] || '',
        childDimension: selectedDimensions[1] || '',
        advertiserId: selectedAdvertiserId,
        startDateTime: selectedStartDate,
        endDateTime: selectedEndDate,
        showUniques: advancedConfigurations?.showUniques,
        methodology: advancedConfigurations?.methodology,
        conversionWindow: advancedConfigurations?.conversionWindow,
        page,
        size,
        order,
        orderBy,
        dimensionFilters: globalFilterDimensions,
      };

      response = yield call(performanceApi.getNestedAnalyticsData, analyticsRequest);
    }

    const authToken: string = yield call(authStorage.getIdToken);
    const updateIconUrl = (record: AnalyticsResponse['data'][0]): AnalyticsResponse['data'][0] => ({
      ...record,
      ICON: record.ICON ? `${record.ICON}&idToken=${authToken}` : record.ICON,
    });
    const filteredResponse = {
      ...response,
      data: response.data.map((dataRecord) => {
        const updatedRecord = updateIconUrl(dataRecord);

        if (Array.isArray(updatedRecord.NESTED) && updatedRecord.NESTED.length) {
          updatedRecord.NESTED = updatedRecord.NESTED.map((nestedRecord) =>
            updateIconUrl(nestedRecord),
          );
        }

        return updatedRecord;
      }),
    };

    yield put(getAnalyticsDataSuccess(filteredResponse));
  } catch (error) {
    yield put(getAnalyticsDataFailure(getErrorMessage(error)));
  }
}

function* changeAnalyticsPageOrderFlow() {
  yield put(getAnalyticsDataStart());
}

function* getCreativesFlow(action: PayloadAction<PagingRequestValues>) {
  try {
    const response: CreativesPagingResponse = yield call(
      performanceApi.getCreatives,
      action.payload,
    );

    yield put(getCreativesSuccess(response.data));
  } catch (error) {
    yield put(getCreativesFailure(getErrorMessage(error)));
  }
}

function* getCreativeNamesFlow(action: PayloadAction<PagingRequestValues>) {
  try {
    const response: CreativeNamesPagingResponse = yield call(
      performanceApi.getCreativeNames,
      action.payload,
    );

    yield put(getCreativeNamesSuccess(response.data));
  } catch (error) {
    yield put(getCreativeNamesFailure(getErrorMessage(error)));
  }
}

function* getPublishersFlow(action: PayloadAction<PagingRequestValues>) {
  try {
    const response: PublishersPagingResponse = yield call(
      performanceApi.getPublishers,
      action.payload,
    );

    const authToken: string = yield call(authStorage.getIdToken);
    const publishers = updateImageUrls(response.data, authToken);

    yield put(getPublishersSuccess(publishers));
  } catch (error) {
    yield put(getPublishersFailure(getErrorMessage(error)));
  }
}

function updateImageUrls(publishers: Publisher[], authToken: string): Publisher[] {
  return publishers.map((publisher) => ({
    ...publisher,
    icon: publisher.icon ? `${publisher.icon}&idToken=${authToken}` : publisher.icon,
  }));
}

function* debounceFetchData() {
  yield delay(500);
  yield call(getChartFlow);
}

export function* performanceSagas() {
  yield all([
    debounce(500, getViewsStart.type, getViewsFlow),
    takeLatest(saveViewStart.type, saveViewFlow),
    takeLatest(deleteViewStart.type, deleteViewFlow),
    debounce(500, getWidgetDataStart.type, getWidgetsFlow),
    takeLatest(getChartDataStart.type, debounceFetchData),
    debounce(500, getAnalyticsDataStart.type, getAnalyticsFlow),
    debounce(500, getBudgetDataStart.type, getBudgetFlow),
    takeLatest(setCurrentViewId.type, changeViewFlow),
    takeLatest(setSelectedMetrics.type, changeSelectedMetricsFlow),
    takeLatest(getCreativesStart.type, getCreativesFlow),
    takeLatest(getCreativeNamesStart.type, getCreativeNamesFlow),
    takeLatest(getPublishersStart.type, getPublishersFlow),
    debounce(500, getIncrementalityDataStart.type, getIncrementalityFlow),
    takeLatest(getHaloDataStart.type, getHaloFlow),
    takeLatest(filterWidgetsStart.type, filterWidgetsFlow),
    takeLatest(setGlobalFilterDimensions.type, setGlobalFilterDimensionsFlow),
    takeLatest(setCumulative.type, setCumulativeFlow),
    takeLatest(setCadence.type, setCadenceFlow),
    takeLatest(setDate.type, setDateFlow),
    takeLatest(setSelectedDimensions.type, setSelectedDimensionsFlow),
    takeLatest(setSelectedColumns.type, setSelectedColumnsFlow),
    takeLatest(setAdvancedConfiguration.type, setAdvancedConfigurationFlow),
    takeLatest(setIncrementalityFilter.type, setIncrementalityFilterFlow),
    takeLatest(setHaloFilter.type, setHaloFilterFlow),
    takeLatest(invalidatePerformanceCache.type, invalidatePerformanceCacheFlow),
    takeLatest(invalidateApiData.type, invalidateApiDataFlow),
    takeLatest(setAnalyticsPage.type, changeAnalyticsPageOrderFlow),
    takeLatest(setAnalyticsPageSize.type, changeAnalyticsPageOrderFlow),
    takeLatest(setAnalyticsOrder.type, changeAnalyticsPageOrderFlow),
    takeLatest(setAnalyticsOrderBy.type, changeAnalyticsPageOrderFlow),
    takeLatest(setActiveTab.type, activeTabFlow),
    takeLatest(setIncrementalityOrder.type, setSelectedDimensionsFlow),
    takeLatest(setHaloOrder.type, setSelectedDimensionsFlow),
  ]);
}
