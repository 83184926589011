import { Flex } from 'antd';
import { useState } from 'react';

import { AdvancedConfiguration } from 'components/AdvancedConfiguration';
import { GlobalFilter } from 'components/GlobalFilter';
import { PerformanceChart } from 'components/PerformanceChart';
import { PerformanceContent } from 'components/PerformanceTable';
import { Views } from 'components/Views';
import { WidgetControl } from 'components/WidgetControl';
import { Card, DateRange, DateRangePicker } from 'components/common';

import { FREQUENCY_METRIC, REACH_METRIC } from 'modules/performance/constants';
import {
  DimensionFilter,
  AdvancedConfiguration as Configuration,
} from 'modules/performance/performanceApiTypes';
import { usePerformance } from 'modules/performance/usePerformance';

export const HomePage = () => {
  const {
    pickDate,
    pickRange,
    dateRange,
    pickGlobalFilterDimensions,
    globalFilterDimensions,
    pickSelectedDimensions,
    selectedDimensions,
    isMetricsSelected,
    isCumulative,
    pickAdvancedConfiguration,
    advancedConfiguration,
    selectedMetrics,
    setMetrics,
  } = usePerformance();
  const [globalFilterTrigger, setGlobalFilterTrigger] = useState<string[] | null>(null);

  const filteredMetrics = isCumulative
    ? selectedMetrics?.filter((metric) => metric !== FREQUENCY_METRIC && metric !== REACH_METRIC)
    : selectedMetrics;

  const handleRangePickerChange = (dateRange: DateRange) => {
    pickDate([dateRange.startDate, dateRange.endDate]);
    pickRange(dateRange.period);
  };

  const handleGlobalFilterChange = (dimensions: DimensionFilter[]) => {
    pickGlobalFilterDimensions(dimensions);
  };

  const handleDimensionsChange = (dimensions: [string | null, string | null]) => {
    pickSelectedDimensions(dimensions);
  };

  const handleAdvancedConfiguration = (data: Configuration) => {
    pickAdvancedConfiguration(data);
  };

  return (
    <div className="w-screen">
      <Flex justify="between" align="center" className="pt-3">
        <div className="text-secondary-black text-heading-3xl font-bold" style={{ flex: 1 }}>
          Performance dashboard
        </div>
        <Flex gap="small" justify="end" align="center">
          <Views />
          <DateRangePicker
            placement="bottomRight"
            value={dateRange}
            onChange={handleRangePickerChange}
          />
        </Flex>
      </Flex>

      <Flex gap="small" className="mt-3">
        <GlobalFilter
          dimensions={globalFilterDimensions || []}
          onChange={handleGlobalFilterChange}
          globalFilterTrigger={globalFilterTrigger}
        />
        <AdvancedConfiguration
          advancedConfiguration={advancedConfiguration as Configuration}
          onChange={handleAdvancedConfiguration}
        />
      </Flex>
      <Card className="mt-3 shadow-none bg-secondary-white border border-primary-gray-100">
        <WidgetControl selectedMetrics={selectedMetrics || []} onChange={setMetrics} />
        <div>
          {isMetricsSelected && <PerformanceChart selectedMetrics={filteredMetrics || []} />}
        </div>
      </Card>
      <div className="pt-3">
        <PerformanceContent
          selectedDimensions={selectedDimensions}
          onChangeDimension={handleDimensionsChange}
          openGlobalFilter={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            setGlobalFilterTrigger(['CAMPAIGN']);
          }}
        />
      </div>
    </div>
  );
};
