export const restrictionDimensionPair = {
  CAMPAIGN: ['CAMPAIGN'],
  MEDIA_TYPE: ['MEDIA_TYPE', 'CAMPAIGN', 'SPLIT'],
  LINE_ITEM: ['LINE_ITEM', 'MEDIA_TYPE', 'CAMPAIGN'],
  AUDIENCE: ['AUDIENCE', 'LINE_ITEM', 'MEDIA_TYPE', 'CAMPAIGN', 'SPLIT'],
  CREATIVE: ['CREATIVE', 'AUDIENCE', 'LINE_ITEM', 'MEDIA_TYPE', 'CAMPAIGN', 'SPLIT'],
  PUBLISHER: ['PUBLISHER', 'MEDIA_TYPE', 'CAMPAIGN', 'SPLIT'],
  SPLIT: ['SPLIT', 'CREATIVE', 'AUDIENCE', 'LINE_ITEM', 'MEDIA_TYPE', 'CAMPAIGN', 'PUBLISHER'],
};

export const visibleSubcategories = ['LEADS', 'PAGE_VISITS'];
