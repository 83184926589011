import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { menuTabsConfig, restrictedRoutes } from 'components/PageLayout/constants';
import { createNavItem, MenuItem, Sidebar, TabKeys } from 'components/Sidebar/Sidebar';
import { Menu } from 'components/common';

import { useAuth } from 'modules/auth/useAuth';
import { RoleKey, UserRoles } from 'modules/user/userApiTypes';

import { Route, ROUTES } from 'routing/constants';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

import { AccountMenu } from '../AccountMenu';
import { Header } from '../Header';
import { UserMenu } from '../UserMenu';

export type PageLayoutProps = {
  className?: string;
  contentClassName?: string;
  displaySidebar?: boolean;
  zeroContentPadding?: boolean;
  showSidebar?: boolean;
  showHeader?: boolean;
  children: ReactNode;
};

const findTabKeyByLink = (link: string): TabKeys | undefined => {
  if (link === ROUTES.HOME) {
    return TabKeys.DASHBOARD;
  }

  if (link === ROUTES.REPORTS) {
    return TabKeys.REPORT_BUILDER;
  }

  return (Object.keys(menuTabsConfig) as TabKeys[]).find((key) =>
    menuTabsConfig[key].some((item) => item.linkTo === link),
  );
};

export const PageLayout = ({
  className,
  contentClassName,
  showSidebar = true,
  showHeader = true,
  children,
}: PageLayoutProps) => {
  const [activeSidebarTab, setActiveSidebarTab] = useState<TabKeys>(TabKeys.DASHBOARD);
  const [selectedTabKey, setSelectedTabKey] = useState<number>(0);
  const navigate = useNavigate();

  const pathName = window.location.pathname;

  const { role } = useAuth();
  const hasLimitedAccess = role === UserRoles.LIMITED_ACCESS.value;

  useEffect(() => {
    if (role && restrictedRoutes[role as RoleKey].includes(window.location.pathname as Route)) {
      navigate(ROUTES.HOME);
    }
  }, [role, navigate]);

  useEffect(() => {
    const activeTab = findTabKeyByLink(pathName);

    if (activeTab) {
      const activeNavItemIndex = menuTabsConfig[activeTab].findIndex(
        (navItem) => navItem.linkTo === pathName,
      );

      setActiveSidebarTab(activeTab);
      setSelectedTabKey(activeNavItemIndex);
    }
  }, [pathName]);

  const navItemsConfig: MenuItem[] = menuTabsConfig[activeSidebarTab]
    .filter((i) => !restrictedRoutes[role as RoleKey].includes(i?.linkTo as Route))
    .map(createNavItem);

  const handleSidebarClick = (menuKey: TabKeys) => {
    setActiveSidebarTab(menuKey);
    setSelectedTabKey(0);
  };

  const hasExternalLink = (navItems: MenuItem[], key: string) =>
    navItems
      .filter((i) => String(i?.key).includes('external'))
      .map((i) => i?.key)
      .includes(key);

  const renderHeader = () =>
    showHeader ? (
      <Header>
        <AccountMenu />
        <UserMenu />
      </Header>
    ) : null;

  const renderContent = () => (
    <Content className="m-6 flex">
      {!hasLimitedAccess && !!navItemsConfig.length && (
        <Menu
          mode="inline"
          className="layout-menu w-64"
          selectedKeys={[String(selectedTabKey)]}
          onSelect={(e) => {
            if (!hasExternalLink(navItemsConfig, e.key)) {
              setSelectedTabKey(Number(e.key));
            }
          }}
          items={navItemsConfig}
        />
      )}
      {children}
    </Content>
  );

  return (
    <section className={classNames('page-background flex min-h-screen w-full', className)}>
      <section className="flex flex-1 justify-center">
        <div className={ctvTwMerge(classNames('flex-1'), contentClassName)}>
          {showSidebar ? (
            <Layout className="bg-secondary-gray">
              <div className="sidebar-container">
                <Sidebar
                  activeSidebarTab={activeSidebarTab}
                  hasLimitedAccess={hasLimitedAccess}
                  onChangeMenu={handleSidebarClick}
                />
              </div>
              <Layout className="bg-secondary-gray">
                {renderHeader()}
                {renderContent()}
              </Layout>
            </Layout>
          ) : (
            <Layout className="bg-secondary-gray">
              {renderHeader()}
              {renderContent()}
            </Layout>
          )}
        </div>
      </section>
    </section>
  );
};
