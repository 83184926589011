/* eslint-disable react-hooks/exhaustive-deps */
import { TableProps } from 'antd';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Edit } from 'feather-icons-react';
import { useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import { ReactComponent as IconChevronSort } from 'assets/images/icons/chevron-selector-vertical.svg';
import { ReactComponent as SyncBroken } from 'assets/images/icons/sync-broken.svg';
import { ReactComponent as Sync } from 'assets/images/icons/sync.svg';

import { Button, IconButton, Modal, Switch, Table } from 'components/common';

import { useOrganization } from 'modules/organization/useOrganization';
import { useXandr } from 'modules/xandr/useXandr';
import { Device, InsertionOrder, LineItem, MediaType } from 'modules/xandr/xandrApiTypes';

import { EditInsertionOrderModal } from './EditInsertionOrderModal';
import { EditLineItemModal } from './EditLineItemModal';
import { XandrConnectionModal } from './XandrConnectionModal';
import { TableData } from './XandrConnectionTypes';
import {
  formatInsertionOrders,
  valueOrDashFormatter,
  currencyOrDashFormatter,
  priceTypeFormatter,
  renderIfInsertionOrder,
  renderIfLineItem,
  countRows,
  getYScroll,
} from './utils';

export const XandrSettings = () => {
  const {
    getInsertionOrders,
    insertionOrders,
    getXandrStatus,
    refreshXandrConnection,
    removeXandrConnection,
    enableInsertionOrder,
    status,
    isLoading,
    syncAllInsertionOrders,
  } = useXandr();
  const { height = 0 } = useWindowSize();
  const { currentAdvertiserId } = useOrganization();
  const [isXandrConnectionModalOpen, setIsXandrConnectionModalOpen] = useState(false);
  const [hasDrafts, setHasDrafts] = useState(false);

  const statusId = status?.id;

  const [isEditInsertionOrderModalOpen, setIsEditInsertionOrderModalOpen] = useState(false);
  const [selectedInsertionOrder, setSelectedInsertionOrder] = useState<InsertionOrder | null>(null);

  const [isEditLineItemModalOpen, setIsEditLineItemModalOpen] = useState(false);
  const [selectedLineItem, setSelectedLineItem] = useState<LineItem | null>(null);

  const openXandrConnectionModal = () => setIsXandrConnectionModalOpen(true);
  const closeXandrConnectionModal = () => setIsXandrConnectionModalOpen(false);

  const openEditInsertionOrderModal = () => setIsEditInsertionOrderModalOpen(true);
  const closeEditInsertionOrderModal = () => {
    setIsEditInsertionOrderModalOpen(false);
  };

  const openEditLineItemModal = () => setIsEditLineItemModalOpen(true);
  const closeEditLineItemModal = () => {
    setIsEditLineItemModalOpen(false);
  };

  const handleSync = () => {
    syncAllInsertionOrders();
  };

  useEffect(() => {
    currentAdvertiserId && getXandrStatus();
  }, [currentAdvertiserId]);

  useEffect(() => {
    currentAdvertiserId && getInsertionOrders();
  }, [statusId]);

  useEffect(() => {
    setHasDrafts(
      !!insertionOrders?.some((io) => io.draftIO || io.lineItems?.some((li) => li.draftLI)),
    );
  }, [insertionOrders]);

  const rows = useMemo(
    () => (insertionOrders?.length ? formatInsertionOrders(insertionOrders) : []),
    [insertionOrders],
  );

  const rowCount = useMemo(
    () => (insertionOrders?.length ? countRows(insertionOrders) : 0),
    [insertionOrders],
  );

  const ioCell = (v: string, { children, enabled, synced }: TableData) => (
    <div
      className={classNames('pr-3 justify-start items-center gap-2 inline-flex', {
        'pl-6': !children,
      })}
    >
      <div className="grow shrink basis-0 text-base-sm font-normal leading-5">
        {valueOrDashFormatter(v)}
      </div>
      {enabled ? (
        synced ? (
          <Sync className="w-4 h-4 relative" />
        ) : (
          <SyncBroken className="w-4 h-4 relative" />
        )
      ) : null}
    </div>
  );

  const columns: TableProps<TableData>['columns'] = [
    {
      dataIndex: 'enabled',
      title: '',
      fixed: 'left',
      width: 60,
      render: (_, row) =>
        row?.children ? (
          <Switch
            disabled={isLoading}
            onChange={(checked) => {
              enableInsertionOrder(row.id, checked);
            }}
            checked={row.enabled || false}
          />
        ) : (
          ''
        ),
    },
    {
      dataIndex: 'name',
      fixed: 'left',
      title: 'Xandr IO / Line item',
      key: 'name',
      sorter: (a, b) => (a.children && b.children ? ('' + a.name).localeCompare(b.name || '') : 0),
      sortIcon: () => <IconChevronSort width={20} height={20} />,
      showSorterTooltip: false,
      width: 256,
      render: ioCell,
      ellipsis: true,
    },
    {
      dataIndex: 'id',
      key: 'id',
      title: 'IO ID',
      width: 128,
      render: renderIfInsertionOrder,
    },
    {
      dataIndex: 'id',
      key: 'lineItemId',
      title: 'Line Item ID',
      width: 128,
      render: renderIfLineItem,
    },
    {
      dataIndex: 'displayName',
      title: 'Campaign name',
      width: 256,
      render: renderIfInsertionOrder,
    },
    {
      dataIndex: 'adGroupName',
      title: 'Ad group name',
      width: 256,
      render: valueOrDashFormatter,
    },
    {
      dataIndex: 'priceType',
      title: 'Price type',
      width: 128,
      render: priceTypeFormatter,
    },

    {
      dataIndex: 'cpm',
      title: 'CPM ',
      width: 128,
      render: currencyOrDashFormatter,
    },
    {
      dataIndex: 'budgetCap',
      title: 'Budget cap',
      width: 128,
      render: currencyOrDashFormatter,
    },
    {
      dataIndex: 'mediaType',
      title: 'Media type',
      width: 128,
      render: (value?: MediaType) => (value ? value.name : '-'),
    },
    {
      dataIndex: 'devices',
      title: 'Device type',
      width: 128,
      render: (value: Device[]) =>
        value && value.length ? value.map((d) => d.name).join(', ') : '-',
    },

    {
      dataIndex: 'actions',
      fixed: 'right',
      title: '',
      width: 60,
      render: (_, data: InsertionOrder | LineItem) => (
        <IconButton
          variant="tertiary"
          className="bg-transparent hover:bg-primary-gray-25"
          size="sm"
          disabled={isLoading}
          Icon={Edit}
          onClick={() => {
            if ('children' in data) {
              setSelectedInsertionOrder(data as InsertionOrder);
              openEditInsertionOrderModal();
            } else {
              setSelectedLineItem(data as LineItem);
              openEditLineItemModal();
            }
          }}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full bg-white rounded-xl border border-primary-gray-100 flex-col justify-start items-start flex">
      <div className="p-8 flex-col justify-start items-start inline-flex w-full">
        <div className="flex-col justify-start items-start gap-4 flex w-full">
          <div className="text-secondary-black text-heading-xl font-semibold leading-relaxed">
            Xandr connection
          </div>
          {status ? (
            <div className="w-full justify-between items-start inline-flex">
              <div className="justify-start items-center gap-2 flex flex-wrap xl:flex-nowrap">
                {hasDrafts ? (
                  <Button variant="primary" size="md" onClick={handleSync} isLoading={isLoading}>
                    Sync to dashboard
                  </Button>
                ) : null}
                <Button
                  variant="secondary"
                  size="md"
                  onClick={refreshXandrConnection}
                  isLoading={isLoading}
                >
                  Refresh Xandr advertiser
                </Button>
                <div className="text-primary-gray-500 text-base-xs font-medium leading-none">
                  Last refreshed on {format(new Date(status?.modificationDate), 'MMM d, yyy')} at{' '}
                  {format(new Date(status?.modificationDate), 'hh:mm aa OOOO')}
                </div>
              </div>
              <div>
                <Button
                  variant="danger"
                  size="md"
                  className="min-w-auto"
                  isLoading={isLoading}
                  onClick={removeXandrConnection}
                >
                  Remove connection
                </Button>
              </div>
            </div>
          ) : (
            <Button
              variant="primary"
              size="md"
              onClick={openXandrConnectionModal}
              isLoading={isLoading}
            >
              Connect Xandr advertiser
            </Button>
          )}
          {rows.length ? (
            <div className="grid w-full rounded-xl border border-primary-gray-100 p-4 pb-0">
              <Table
                dataSource={rows}
                columns={columns}
                pagination={false}
                scroll={{ x: 1500, ...getYScroll(rowCount, height) }}
                expandable={{
                  defaultExpandAllRows: true,
                  showExpandColumn: false,
                  expandedRowClassName: () => 'ctv-expanded-row',
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        open={isXandrConnectionModalOpen}
        footer={null}
        closable={false}
        destroyOnClose
        onCancel={closeXandrConnectionModal}
      >
        <XandrConnectionModal onClose={closeXandrConnectionModal} />
      </Modal>
      {selectedInsertionOrder ? (
        <Modal
          open={isEditInsertionOrderModalOpen}
          footer={null}
          destroyOnClose={true}
          closable={false}
          onCancel={closeEditInsertionOrderModal}
        >
          <EditInsertionOrderModal
            onClose={closeEditInsertionOrderModal}
            selectedInsertionOrder={selectedInsertionOrder}
          />
        </Modal>
      ) : null}
      {selectedLineItem ? (
        <Modal
          open={isEditLineItemModalOpen}
          footer={null}
          destroyOnClose={true}
          closable={false}
          onCancel={closeEditLineItemModal}
        >
          <EditLineItemModal onClose={closeEditLineItemModal} selectedLineItem={selectedLineItem} />
        </Modal>
      ) : null}
    </div>
  );
};
