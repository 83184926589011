import { CheckboxOptionType } from 'antd';
import { ReactNode, useState } from 'react';
import { copyToClipboard } from 'utils';

import { ReactComponent as Copy } from 'assets/images/icons/copy.svg';

import { CheckboxGroup, IconButton, TextArea } from 'components/common';
import { Select, Option } from 'components/common/select';

import { useOrganization } from 'modules/organization/useOrganization';

import { exampleValues } from './constants';

type ExampleCategoryProps = {
  category: ExampleCategory;
  children: ReactNode;
  withValues?: boolean;
};

type ExampleCategory = {
  name: string;
  subcategories: string[];
};

export const ExampleCategory = ({
  category,
  children,
  withValues = false,
}: ExampleCategoryProps) => {
  const { name: categoryName, subcategories } = category;
  const { currentAdveriser } = useOrganization();
  const [selectedSubcategory, setSelectedSubcategory] = useState(subcategories[0]);
  const [selectedValue, setSelectedValue] = useState<CheckboxOptionType[]>([]);

  const subcategoryString = withValues
    ? ''
    : `,
'subcategory': '${selectedSubcategory}',`;
  const selectedValuesString = `${selectedValue.length ? ',\n' : ''}${selectedValue.join(',\n')}`;
  const pixelValue = `<script src='${process.env.REACT_APP_PIXEL_CDN_URL}'></script>
<script>CMPIXEL.fire('${currentAdveriser?.pixelCode}', {
'category': '${categoryName}'${subcategoryString}${selectedValuesString}
});
</script>`;

  const handleCopy = () => {
    copyToClipboard(pixelValue);
  };

  return (
    <div className="w-full justify-start items-start gap-2 flex">
      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex">
        <div className="text-secondary-black text-base-md font-semibold leading-snug">
          {children}
        </div>
        <div className="gap-2 w-full justify-between items-center inline-flex">
          {withValues ? (
            <CheckboxGroup
              onChange={setSelectedValue}
              options={
                exampleValues?.map(({ name, value }) => ({
                  label: name,
                  value,
                })) || []
              }
              className="gap-3 [&_span]:text-base-sm [&_span]:font-medium"
            />
          ) : (
            <Select
              className="min-w-64 max-w-72"
              onChange={setSelectedSubcategory}
              value={selectedSubcategory}
            >
              {subcategories?.map((name, i) => (
                <Option key={`subcategories-${i}`} value={name}>
                  <div className="justify-start items-center gap-1.5 flex">
                    <div className="text-primary-gray-500 text-base-xs font-medium leading-none">
                      Sub-category:
                    </div>
                    <div className="text-secondary-black text-base-sm font-normal leading-tight">
                      {name}
                    </div>
                  </div>
                </Option>
              ))}
            </Select>
          )}
          <IconButton Icon={Copy} variant="outline" onClick={handleCopy} />
        </div>
        <TextArea disabled value={pixelValue} className="leading-5"></TextArea>
      </div>
    </div>
  );
};
