import { Flex, Layout } from 'antd';

import { IncrementalityFilter } from 'components/Incrementality/IncrementalityFilter';
import { IncrementalitySidebar } from 'components/Incrementality/IncrementalitySidebar';
import { IncrementalityTable } from 'components/Incrementality/IncrementalityTable';
import { Button } from 'components/common';

import { usePerformance } from 'modules/performance/usePerformance';

export const Incrementality = ({ openGlobalFilter }: { openGlobalFilter: () => void }) => {
  const { pickIncrementalityFilter, incrementalityFilters } = usePerformance();

  return (
    <>
      <Flex gap="small" align="center" justify="space-between" className="h-[52px] pl-6 pr-3 pt-3 ">
        <Flex gap="large">
          <div className="justify-start items-center gap-1.5 flex">
            <div className="w-2 h-2 bg-primary-electric-500 rounded-full" />
            <div className="text-base-sm font-medium leading-tight">Exposed conversion rate</div>
          </div>
          <div className="justify-start items-center gap-1.5 flex">
            <div className="w-2 h-2 bg-primary-gray-200 rounded-full" />
            <div className="text-base-sm font-medium leading-tight">Control conversion rate</div>
          </div>
        </Flex>
        <Flex gap="small">
          <IncrementalityFilter
            onChange={pickIncrementalityFilter}
            incrementalityFilter={incrementalityFilters}
          />
          <Button size="md" className="ml-6 " onClick={openGlobalFilter}>
            Choose new campaign
          </Button>
        </Flex>
      </Flex>
      <Layout className="p-4 bg-white">
        <Layout className="bg-white">
          <IncrementalityTable />
        </Layout>
        <IncrementalitySidebar />
      </Layout>
    </>
  );
};
