import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronLeft } from 'feather-icons-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useDebounceCallback } from 'usehooks-ts';
import { object, string, ref } from 'yup';

import {
  Button,
  Field,
  Wordmark,
  VideoBackground,
  Progress,
  IconButton,
  PassWordRulesBlock,
  Indicator,
} from 'components/common';
import { Input, PasswordInput } from 'components/common/input/input';
import { Paragraph, Text } from 'components/common/typography';
import { passwordRegex } from 'components/constants';

import { SignupPayload, PasswordConfirmation } from 'modules/auth/types';
import { useAuth } from 'modules/auth/useAuth';

const schema = object()
  .shape({
    fullname: string().required('Name is required'),
    username: string().email('Please enter a valid email address.').required('Email is required'),
    tempPassword: string().required('Temporary Password is required'),
    password: string()
      .min(8, 'Password does not meet the requirements.')
      .matches(passwordRegex, 'Password does not meet the requirements.')
      .required('New password is required'),
    confirmPassword: string()
      .required('Confirm password is required')
      .oneOf([ref('password')], 'Your passwords do not match.'),
  })
  .required();

export const SignupPage = () => {
  const { signup, isLoading, errorText } = useAuth();
  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(1);

  const companyName = searchParams.get('company') || '';

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    trigger,
    getFieldState,
  } = useForm<SignupPayload & PasswordConfirmation>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      fullname: '',
      username: '',
      password: '',
      tempPassword: '',
    },
  });

  // Querystring should be like this: http://localhost:3000/signup?company=coolmedia
  // Info for Signup email template
  const handleFormSubmit = handleSubmit(signup);

  const { fullname, username, password } = watch();

  const debouncedTrigger = useDebounceCallback(trigger, 500);

  useEffect(() => {
    username && debouncedTrigger('username');
  }, [username, debouncedTrigger]);

  const nextDisabled = !fullname || !username || !!(username && getFieldState('username').invalid);

  const firstStep = (
    <>
      <Wordmark variant="gradient-dark" className="w-40 h-7" />
      <form onSubmit={handleFormSubmit} className="w-full">
        <div className="stretch-to-form gap-10">
          <div className="stretch-to-form gap-3">
            <h1 className="self-stretch text-heading-4xl font-bold leading-snug text-secondary-black tracking-[-.8px]">
              Welcome!
            </h1>
            <Paragraph className="text-primary-gray-500">
              You’ve been invited to join the{' '}
              <Text className="text-secondary-black font-semibold">{companyName}</Text> Performance
              Dashboard. Please fill out this registration form to create your user profile.
            </Paragraph>
          </div>
          <div className="stretch-to-form gap-3">
            <div className="stretch-to-form gap-6">
              <Field
                label={'Name'}
                render={({ field, fieldState: { invalid } }) => (
                  <Input {...field} invalid={invalid} large />
                )}
                name="fullname"
                control={control}
                error={errors.fullname?.message?.toString()}
              />
              <Field
                label={'Email'}
                render={({ field, fieldState: { invalid } }) => (
                  <Input {...field} invalid={invalid} large />
                )}
                name="username"
                control={control}
                error={errors.username?.message?.toString()}
              />
            </div>
          </div>
          <Button
            size="lg"
            variant="primary"
            className="w-full"
            onClick={() => setCurrentStep(2)}
            disabled={nextDisabled}
          >
            Next
          </Button>
        </div>
        <div className="self-stretch flex-col justify-center items-center gap-3 flex w-full mt-5">
          <Progress currentStep={currentStep} steps={2} />
        </div>
      </form>
    </>
  );

  const secondStep = (
    <form onSubmit={() => {}} className="w-full">
      <div className="stretch-to-form gap-6">
        <IconButton Icon={ChevronLeft} variant="tertiary" onClick={() => setCurrentStep(1)} />
        <Field
          label={'Temporary password'}
          render={({ field, fieldState: { invalid } }) => (
            <Input {...field} invalid={invalid} large />
          )}
          name="tempPassword"
          control={control}
          error={errors.tempPassword?.message?.toString()}
        />
        <div className="stretch-to-form gap-1.5">
          <Field
            label={'New password'}
            render={({ field, fieldState: { invalid } }) => (
              <PasswordInput {...field} invalid={invalid} />
            )}
            name="password"
            control={control}
            error={errors.password?.message?.toString()}
          />
          <PassWordRulesBlock password={password} />
        </div>
        <Field
          label={'Confirm password'}
          render={({ field, fieldState: { invalid } }) => (
            <PasswordInput {...field} invalid={invalid} />
          )}
          name="confirmPassword"
          control={control}
          error={errors.confirmPassword?.message?.toString()}
        />
        {errorText && (
          <Indicator variant="danger" size="lg">
            {errorText}
          </Indicator>
        )}
        <Button
          size="lg"
          variant="primary"
          isLoading={isLoading}
          className="w-full"
          disabled={!isValid}
          onClick={handleFormSubmit}
        >
          Create user profile
        </Button>
      </div>
      <div className="self-stretch flex-col justify-center items-center gap-3 flex w-full mt-5">
        <Progress currentStep={currentStep} steps={2} />
      </div>
    </form>
  );

  return (
    <VideoBackground>
      <div className="dialog w-128 p-10 bg-white rounded-xl shadow flex-col justify-start items-center inline-flex">
        <div className="stretch-to-form gap-12">
          {currentStep === 1 && firstStep}
          {currentStep === 2 && secondStep}
        </div>
      </div>
    </VideoBackground>
  );
};
