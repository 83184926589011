import { copyToClipboard } from 'utils';

import { ReactComponent as Copy } from 'assets/images/icons/copy.svg';

import { IconButton, Input } from 'components/common';

import { useOrganization } from 'modules/organization/useOrganization';

import { ExampleCategory } from './ExampleCategory';
import { categories } from './constants';

export const PixelCode = () => {
  const { currentAdveriser } = useOrganization();
  const { pagevisit, activity, checkout, leads } = categories;

  const handleCopy = () => {
    copyToClipboard(currentAdveriser?.pixelCode as string);
  };

  return (
    <div className="w-full bg-white rounded-xl border border-primary-gray-100 flex-col justify-start items-start flex">
      <div className="p-8 w-184 flex-col justify-start items-start gap-8 inline-flex">
        <div className="flex-col justify-start items-start gap-2 flex">
          <div className=" flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-secondary-black text-xl font-semibold leading-relaxed">
              Universal pixel
            </div>
            <div className="self-stretch text-primary-gray-500 text-base-sm font-normal leading-5">
              Welcome! To get started, place your Universal pixel. The pixel can be used to track a
              variety of things, due to this, the pixel code and how it is placed varies. Please
              refer to the pixel implementation guide for documentation on how to set it up.
            </div>
          </div>
        </div>

        <div className="w-full justify-start items-start gap-2 flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex">
            <div className="grow shrink basis-0 text-secondary-black text-base-xs font-medium  leading-none">
              Your pixel code
            </div>
            <div className="gap-2 self-stretch inline-flex">
              <Input disabled value={currentAdveriser?.pixelCode} />
              <IconButton Icon={Copy} variant="outline" size="md" onClick={handleCopy}></IconButton>
            </div>
          </div>
        </div>

        <ExampleCategory category={pagevisit}>Pixel code for tracking page visits:</ExampleCategory>
        <ExampleCategory category={activity}>Pixel code for tracking activity:</ExampleCategory>
        <ExampleCategory category={leads}>Pixel code for tracking leads:</ExampleCategory>
        <ExampleCategory category={checkout} withValues>
          Pixel code for tracking checkouts with values:
        </ExampleCategory>

        <div className="flex-col justify-start items-start gap-3 flex">
          <div className="self-stretch text-secondary-black text-base-md font-semibold  leading-snug">
            Replace the following variables:
          </div>
          <div className="self-stretch text-secondary-black text-base-sm font-normal leading-relaxed">
            &bull; <span className="text-base-sm bg-system-warning-500">ORDER_NUMBER</span> with the
            order number of the transaction. Alphanumeric.
            <br />
            &bull; <span className="text-base-sm bg-system-warning-500">ORDER_AMOUNT</span> with the
            dollar amount for the order (E.g. &quot;12.75&quot;, 12, or 12.75 are all acceptable)
            <br />
            &bull; <span className="text-base-sm bg-system-warning-500">CURRENCY</span> with the
            currency code as per ISO 4217 Standard. Eg USD, EUR, etc.
          </div>
          <div className="self-stretch">
            <span className="text-primary-gray-500 text-base-sm font-semibold leading-5">
              Note:{' '}
            </span>
            <span className="text-primary-gray-500 text-base-sm font-normal leading-5">
              The order confirmation page must have both the order number and amount values
              available to pass into this call.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
