import { Avatar } from 'antd';
import { ChevronDown, ChevronUp, LogOut } from 'feather-icons-react';
import { useState } from 'react';

import { ReactComponent as EmptyAvatar } from 'assets/images/icons/emptyAvatar.svg';

import { Dropdown, Card, Divider, Button } from 'components/common';

import { useAuth } from 'modules/auth/useAuth';

export const UserMenu = () => {
  const { email, fullname, logout, userpic } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      className="flex"
      trigger={['click']}
      onOpenChange={(open) => setIsOpen(open)}
      dropdownRender={() => (
        <Card style={{ width: 320 }}>
          <div className="p-4 text-base-sm break-words">
            <div className="font-medium">{fullname}</div>
            <div className="font-normal text-primary-gray-500">{email}</div>
          </div>
          <Divider className="my-1" />
          <div className="p-2">
            <Button
              size="lg"
              variant="secondary"
              Icon={LogOut}
              onClick={logout}
              className="py-5 w-full font-medium"
            >
              Logout
            </Button>
          </div>
        </Card>
      )}
    >
      <div>
        <Button
          variant="secondary"
          className="ctv-user-menu-button min-w-16 w-16 border-2 bg-primary-gray-50"
        >
          <div className="flex items-center">
            <Avatar
              src={userpic}
              className="object-contain bg-primary-gray-100"
              icon={<EmptyAvatar />}
            />
            {isOpen ? (
              <ChevronUp size="20" strokeWidth="1.5" />
            ) : (
              <ChevronDown size="20" strokeWidth="1.5" />
            )}
          </div>
        </Button>
      </div>
    </Dropdown>
  );
};
