import { Skeleton as SkeletonAntd, SkeletonProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

export const Skeleton = memo(({ className, ...rest }: SkeletonProps) => {
  return <SkeletonAntd className={ctvTwMerge(classNames('ctv-skeleton'), className)} {...rest} />;
});

Skeleton.displayName = 'Skeleton';

export const WidgetSkeleton = memo(({ className, ...rest }: { className?: string }) => {
  return (
    <div
      className={ctvTwMerge(classNames('flex w-full h-[140px] p-6 flex-col gap-5'), className)}
      {...rest}
    >
      <SkeletonAntd.Input active className="w-[120px] h-4 min-w-0" />
      <div className="flex gap-2 flex-col">
        <SkeletonAntd.Input active className="w-52 h-8" />
        <SkeletonAntd.Input active className="w-[140px] h-4 min-w-0" />
      </div>
    </div>
  );
});

WidgetSkeleton.displayName = 'WidgetSkeleton';
