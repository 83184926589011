import { Avatar as AntdAvatar } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';

import { ReactComponent as EmptyAvatar } from 'assets/images/icons/emptyAvatar.svg';

import { Button, LinkButton } from 'components/common';
import { UploadButton, UploadFile } from 'components/common/UploadButton';

import { useUser } from 'modules/user/useUser';

import { JPEG_FILE_MIME_TYPE, PNG_FILE_MIME_TYPE } from 'utils/constants';

type AvatarProps = {
  src?: string;
};

export const Avatar = ({ src }: AvatarProps) => {
  const { deleteUserpic, uploadUserpic } = useUser();

  const handleFileUpload = async ({ file }: UploadChangeParam<UploadFile>) => {
    uploadUserpic(file);
  };

  return src ? (
    <div className="justify-start items-center gap-6 inline-flex">
      <div className="w-20 h-20 rounded-full">
        <AntdAvatar
          src={src}
          size={80}
          icon={<EmptyAvatar />}
          className="rounded-full object-contain bg-primary-gray-100"
        />
      </div>
      <UploadButton
        onChange={handleFileUpload}
        accept={`${PNG_FILE_MIME_TYPE}, ${JPEG_FILE_MIME_TYPE}`}
      >
        <Button variant="secondary" size="md">
          Change profile picture
        </Button>
      </UploadButton>
      <LinkButton
        size="sm"
        className="text-system-danger-500 hover:text-system-danger-300"
        onClick={deleteUserpic}
      >
        Delete
      </LinkButton>
    </div>
  ) : (
    <div className="justify-start items-center gap-6 inline-flex">
      <EmptyAvatar className="w-20 h-20 rounded-full bg-primary-gray-100" />
      <UploadButton onChange={handleFileUpload}>
        <Button variant="secondary" size="md">
          Upload profile picture
        </Button>
      </UploadButton>
    </div>
  );
};
