import classNames from 'classnames';
import { ReactNode } from 'react';
import { Controller, Control, ControllerProps, FieldValues, FieldPath } from 'react-hook-form';

import { ExtractedRenderType } from '../formField/types';
import { Tooltip } from '../tooltip';

type FieldProps<TName extends FieldPath<TFieldValues>, TFieldValues extends FieldValues> = Omit<
  ControllerProps<TFieldValues, TName>,
  'control'
> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  error?: string;
  label?: string;
  isOptional?: boolean;
  tooltip?: string;
  className?: string;
  secondaryLabel?: ReactNode;
  render: ExtractedRenderType<TFieldValues, TName>;
};

export const Field = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  render,
  name,
  error,
  label,
  isOptional,
  tooltip,
  className,
  secondaryLabel = <></>,
  ...rest
}: FieldProps<TName, TFieldValues>) => {
  return (
    <div className={classNames('w-full', className)}>
      {label || tooltip ? (
        <div className="mb-1.5 flex items-center justify-between">
          <div className="flex items-center">
            {label ? <FieldLabel htmlFor={name}>{label}</FieldLabel> : null}
            {tooltip ? <FieldTooltip tooltip={tooltip} /> : null}
          </div>
          {isOptional ? <FieldOptionalLabel>{''}</FieldOptionalLabel> : null}
          {secondaryLabel}
        </div>
      ) : null}
      <Controller render={render} name={name} {...rest} />
      {error ? <FieldError>{error}</FieldError> : null}
    </div>
  );
};

export const FieldLabel = ({ htmlFor, children }: { htmlFor: string; children: ReactNode }) => (
  <label className="text-base-sm leading-tight font-medium text-secondary-black" htmlFor={htmlFor}>
    {children}
  </label>
);

export const FieldTooltip = ({ tooltip }: { tooltip: string }) => (
  <Tooltip placement="top" title={tooltip} />
);

export const FieldOptionalLabel = ({ children }: { children: ReactNode }) => (
  <span className="text-base leading-5 text-text-60">{children}</span>
);

export const FieldError = ({ children }: { children: ReactNode }) => (
  <div className="mt-1.5 font-medium text-base-sm leading-4 text-system-danger-500">{children}</div>
);
