/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Image, Typography, type TableProps } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatNumberToLocale } from 'utils';

import { Table, TablePagination } from 'components/common';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';
import { usePerformance } from 'modules/performance/usePerformance';

import { UNKNOWN_DIMENSION_NAMES } from './constants';

type DataType = Record<string, unknown>;

const { Text } = Typography;

const flattenNestedData = (data: DataType[]): DataType[] => {
  const flattenedData: DataType[] = [];

  data.forEach((row) => {
    const { NESTED: nestedData, ...rest } = row;

    flattenedData.push({ ...rest, FIRST_DIMENSION: true });
    if (nestedData) {
      flattenedData.push(...(nestedData as DataType[]));
    }
  });

  return flattenedData;
};

export const AdvancedAnalyticsTable: React.FC = () => {
  const {
    selectedView,
    views,
    widgets,
    analytics,
    selectedDimensions,
    isGridLoading,
    analyticsPage,
    pickAnalyticsPage,
    analyticsPageSize,
    pickAnalyticsPageSize,
    pickAnalyticsOrder,
    pickAnalyticsOrderBy,
    selectedColumns,
  } = usePerformance();
  const [data, setData] = useState<DataType[]>();
  const [columns, setColumns] = useState<ColumnType<DataType>[]>([]);
  const { data: constants } = useConstantsQuery(null);
  const metricsDisplay = useMemo(() => (constants && constants.Metrics) || {}, [constants]);
  const dimensionsDisplay = useMemo(() => (constants && constants.Dimensions) || {}, [constants]);

  const renderFirstColumn = useCallback(
    (text: string, record: DataType) => {
      const icon = record.ICON ? (
        <Image src={record.ICON as string} preview={false} className="rounded w-10" />
      ) : null;
      let textDisplay = UNKNOWN_DIMENSION_NAMES[text] || text;

      if (!record.FIRST_DIMENSION && selectedDimensions[1] && record[selectedDimensions[1]]) {
        const secondDimensionName = record[selectedDimensions[1]] as string;

        textDisplay = UNKNOWN_DIMENSION_NAMES[secondDimensionName] || secondDimensionName;
      }

      return (
        <Flex align="center" gap={10}>
          <div className="min-w-10"> {icon}</div>
          <Flex vertical>
            <Text>{`${textDisplay || '-'}`}</Text>
            {!!record.DESCRIPTION && (
              <Text type="secondary" className="text-base-xs">{`${record.DESCRIPTION}`}</Text>
            )}
          </Flex>
        </Flex>
      );
    },
    [selectedDimensions],
  );

  const getFirstColumn = useCallback((): ColumnType<DataType> => {
    if (!selectedDimensions[0]) {
      return {};
    }

    const firstDimensionName =
      (selectedDimensions[0] && dimensionsDisplay[selectedDimensions[0]]?.shownName) ||
      selectedDimensions[0];
    const secondDimensionName =
      (selectedDimensions[1] && dimensionsDisplay[selectedDimensions[1]]?.shownName) ||
      selectedDimensions[1];
    const title = [firstDimensionName, secondDimensionName].filter(Boolean).join(' / ');

    return {
      title,
      dataIndex: selectedDimensions[0],
      sorter: true,
      fixed: 'left',
      className: 'w-96 min-w-96 border-r border-primary-gray-100',
      render: renderFirstColumn,
    };
  }, [selectedDimensions, dimensionsDisplay]);

  const getMetrics = useCallback((): ColumnType<DataType>[] => {
    const viewMetrics = views?.find((view) => view.viewId === selectedView)?.metrics || [];
    const metrics = ((selectedColumns?.length && selectedColumns) || viewMetrics).map((metric) => ({
      title: metricsDisplay[metric]?.shownName || metric,
      dataIndex: metric,
      sorter: true,
      render: (value: string) => value || '-',
    }));

    return metrics;
  }, [selectedView, views, metricsDisplay, selectedColumns]);

  const getTotalRow = useCallback(
    (data: DataType[], columns: ColumnType<DataType>[]) => {
      const totalRow: Record<string, unknown> = {};

      columns.forEach((column) => {
        const dataIndex = column.dataIndex as string;

        totalRow[dataIndex] = widgets?.current?.[dataIndex];
      });

      if (selectedDimensions[0]) {
        totalRow[selectedDimensions[0] as string] = 'Total';
      }

      totalRow.TOTAL_ROW = true;

      return totalRow;
    },
    [selectedDimensions, widgets],
  );

  const formatData = useCallback(
    (data: DataType[]) =>
      data
        .map((row) => {
          const formattedRow = { ...row };

          Object.keys(row).forEach((key) => {
            if (typeof row[key] === 'number') {
              formattedRow[key] = formatNumberToLocale(row[key] as number, {
                style: metricsDisplay[key]?.unit === '$' ? 'currency' : undefined,
                suffix: metricsDisplay[key]?.unit === '%' ? '%' : undefined,
              });
            }
          });

          return formattedRow;
        })
        .map((row, i) => ({ ...row, key: i })),
    [metricsDisplay],
  );

  useEffect(() => {
    const firstColumn = getFirstColumn();
    const metrics = getMetrics();

    setColumns([firstColumn, ...metrics]);
  }, [getFirstColumn, getMetrics]);

  useEffect(() => {
    if (columns && columns.length > 1 && analytics?.data && widgets?.current) {
      const data = flattenNestedData(analytics.data);
      const totalRow = getTotalRow(data, columns);

      data.push(totalRow);
      const formattedData = formatData(data);

      setData(formattedData);
    } else {
      setData([]);
    }
  }, [columns, analytics]);

  useEffect(() => {
    pickAnalyticsPage(0);
  }, [selectedDimensions]);

  const handleTableChange: TableProps<DataType>['onChange'] = (_pagination, _filters, sorter) => {
    const sorterOrder = Array.isArray(sorter) || !sorter?.order ? null : sorter.order;
    const sorterField = Array.isArray(sorter) || !sorter?.field ? null : sorter.field;
    const field = Array.isArray(sorterField) || !sorterField ? null : (sorterField as string);

    const order = (sorterOrder && (sorterOrder === 'descend' ? 'DESC' : 'ASC')) || null;
    const orderBy = order && field && !selectedDimensions.includes(field) ? field : null;

    pickAnalyticsOrder(order);
    pickAnalyticsOrderBy(orderBy);
  };

  return (
    <div className="px-3 pt-3 pb-0 grid w-full">
      <Table
        className="ctv-analytics-table overflow-x-auto overflow-y-hidden p-0"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={isGridLoading && { indicator: <LoadingOutlined spin /> }}
        onChange={handleTableChange}
        rowClassName={(record) => {
          if (record.TOTAL_ROW) {
            return 'total-row';
          }
          if (record.FIRST_DIMENSION) {
            return 'first-dimension-row';
          }

          return 'second-dimension-row';
        }}
      />
      <TablePagination
        totalPages={analytics?.totalPages || 0}
        totalCount={analytics?.totalCount}
        pageSize={analyticsPageSize}
        setPageSize={pickAnalyticsPageSize}
        page={analyticsPage}
        setPage={pickAnalyticsPage}
      />
    </div>
  );
};
