import { ValueType } from 'rc-input-number';

import { InsertionOrder, LineItem, PriceType } from 'modules/xandr/xandrApiTypes';

export const priceTypes = {
  [PriceType.ADDED]: {
    value: PriceType.ADDED,
    label: 'Added value',
  },
  [PriceType.CPM]: {
    value: PriceType.CPM,
    label: 'CPM',
  },
};

export const currencyFormatter = (value: ValueType = '') => {
  const parts = `$${value}`.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1]) parts[1] = parts[1].slice(-2);

  return parts.join('.');
};
export const currencyParser = (value: string = '') =>
  value?.replace(/\$\s?|(,*)/g, '') as ValueType;

const formatLineItems = (items: LineItem[]) =>
  items.map((i) => {
    if (i.draftLI) {
      const { id, displayName, ...rest } = i.draftLI;

      return {
        id: -id,
        adGroupName: displayName,
        displayName,
        ...rest,
        synced: i.synced,
        enabled: i.enabled,
      };
    }

    return { ...i, adGroupName: i.displayName };
  });

export const formatInsertionOrders = (data: InsertionOrder[]) =>
  data.map((io) => {
    if (io.draftIO) {
      const { id, name, displayName } = io.draftIO;

      return {
        id: -id,
        name,
        displayName,
        key: -id,
        children: formatLineItems(io.lineItems || []),
        synced: io.synced,
        enabled: io.enabled,
      };
    }

    return {
      ...io,
      key: io.id,
      children: formatLineItems(io.lineItems || []),
    };
  });

export const countRows = (data: InsertionOrder[]) =>
  data.reduce((acc, cur) => acc + (cur.lineItems ? cur.lineItems?.length + 1 : 0), 0);

export const valueOrDashFormatter = (value: string) => (value ? value : '-');
export const currencyOrDashFormatter = (value: string) => (value ? currencyFormatter(value) : '-');
export const priceTypeFormatter = (value: PriceType) => (value ? priceTypes[value].label : '-');
export const renderIfInsertionOrder = (value: string, data: InsertionOrder | LineItem) =>
  'children' in data ? value : '-';
export const renderIfLineItem = (value: string, data: InsertionOrder | LineItem) =>
  !('children' in data) ? value : '-';

const TABLE_OFFSET = 355;
const APPROX_ROW_SIZE = 80;
const rowsWithoutScroll = (height: number) => (height - TABLE_OFFSET) / APPROX_ROW_SIZE;

export const getYScroll = (rowCount: number, height: number) =>
  rowCount > rowsWithoutScroll(height) ? { y: height - TABLE_OFFSET } : {};
