import { useState } from 'react';
import { string, number } from 'yup';

import { Button, Input, Modal, FieldLabel } from 'components/common';
import { FormField } from 'components/common/formField';

import { UserRequestValues } from 'modules/auth/types';
import { useAuth } from 'modules/auth/useAuth';

import { Avatar } from './Avatar';
import { ChangePasswordModal } from './ChangePasswordModal';

const nameSchema = string().trim().required('Name is required');
//need to come up with proper phone validation
const phoneSchema = number().required('Phone is required').typeError('Invalid phone number');

export const UserSettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id, phone, email, fullname, userpic, updateUser } = useAuth();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const changeUser = (data: Partial<UserRequestValues>) => {
    updateUser({
      subId: id || '',
      ...data,
    });
  };

  return (
    <div className="w-full h-full bg-white rounded-xl border border-primary-gray-100 flex-col justify-start items-start flex">
      <div className="p-8 flex-col justify-start items-start inline-flex">
        <div className="flex-col w-full justify-start items-start gap-4 flex">
          <div className="text-secondary-black text-heading-xl font-semibold leading-relaxed">
            User
          </div>
          <div className="self-stretch w-full flex-col justify-start items-start gap-6 flex">
            <Avatar src={userpic} />
            <FormField
              name="fullName"
              label="Name"
              onSubmit={changeUser}
              value={fullname}
              render={(readOnly) =>
                // eslint-disable-next-line react/display-name, react/prop-types
                ({ field, fieldState: { invalid } }) => (
                  <Input {...field} invalid={!readOnly && invalid} readOnly={readOnly} />
                )}
              fieldSchema={nameSchema}
            />
            <div className="self-stretch justify-start items-start gap-2 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex w-96">
                <FieldLabel htmlFor="email">Email</FieldLabel>
                <Input readOnly value={email} />
              </div>
              <div className="w-10 pt-6 justify-start items-start flex"></div>
            </div>
            <FormField
              name="phone"
              label="Phone"
              onSubmit={changeUser}
              render={(readOnly) =>
                // eslint-disable-next-line react/display-name, react/prop-types
                ({ field, fieldState: { invalid } }) => (
                  <Input {...field} invalid={!readOnly && invalid} readOnly={readOnly} />
                )}
              fieldSchema={phoneSchema}
              value={phone}
            />
            <div className="pt-3 flex-col justify-start items-start flex">
              <Button variant="outline" size="md" onClick={openModal}>
                Change password
              </Button>
            </div>
            <Modal open={isModalOpen} footer={null} closable={false}>
              <ChangePasswordModal onClose={closeModal}></ChangePasswordModal>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};
