/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';

import { FilterDimension } from 'components/FilterDimension';
import { Dimensions, Metrics } from 'components/Incrementality/incrementalityTypes';

import { IncrementalityFilters } from 'modules/performance/performanceApiTypes';

export const IncrementalityFilter = ({
  incrementalityFilter,
  onChange,
}: {
  incrementalityFilter: IncrementalityFilters | null | undefined;
  onChange: (data: IncrementalityFilters) => void;
}) => {
  const dimension = incrementalityFilter?.dimension || Dimensions.OVERALL.value;
  const metric = incrementalityFilter?.metric || Metrics.LEADS.value;

  const getOptions = useCallback(
    (items: typeof Dimensions | typeof Metrics) =>
      Object.entries(items).map(([key, { label }]) => ({
        label,
        value: key,
      })),
    [],
  );

  return (
    <>
      <FilterDimension
        prefixText="Dimension:"
        allOptions={getOptions(Dimensions)}
        selectedOption={dimension}
        onChange={(dimension) => {
          onChange({ metric, dimension });
        }}
      />
      <FilterDimension
        prefixText="Metric:"
        allOptions={getOptions(Metrics)}
        selectedOption={metric}
        onChange={(metric) => {
          onChange({ metric, dimension });
        }}
      />
    </>
  );
};
